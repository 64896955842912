:root {
    --water-color: #4caf50; /* цвет заливки теперь Primary */
    --wave-height: 20px;    /* высота волны */
}

/* Контейнер таба должен быть позиционирован относительно для корректного отображения заполнения */
.network-active {
    position: relative;
    z-index: 0;
    overflow: hidden;
    animation: textColorChange 3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

/* Псевдоэлемент ::before отвечает за вертикальное заполнение (эффект «заливания») */
.network-active::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background: var(--water-color);
    animation: fillUp 3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    z-index: -1;
}

.network-active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200%;
    height: var(--wave-height);
    background: var(--water-color);

    /* Пример многоугольника, имитирующего волну из нескольких пиков */
    clip-path: polygon(
            0% 100%,
            10% 85%,
            20% 90%,
            30% 75%,
            40% 85%,
            50% 70%,
            60% 85%,
            70% 75%,
            80% 90%,
            90% 85%,
            100% 100%
    );

    /* Анимации (горизонтальное колебание + подъём волны) */
    animation: waveMotion 2s infinite linear, waveRise 3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    z-index: -1;
}

/* Анимация вертикального заполнения: псевдоэлемент ::before увеличивает свою высоту от 0% до 100% */
@keyframes fillUp {
    from { height: 0%; }
    to { height: calc(100% + var(--wave-height)); }
}

/* Анимация горизонтального движения волны */
@keyframes waveMotion {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
}

/* Анимация подъёма волны вместе с заполнением: волна (::after) двигается вверх синхронно с ::before */
@keyframes waveRise {
  0% { bottom: 0; }
  80% { bottom: 0; }
  100% { bottom: calc(100% - var(--wave-height)); }
}

@keyframes textColorChange {
    0% { color: black; }
    100% { color: var(--primary-color); }
}